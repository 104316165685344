import {
  IonContent, IonHeader, IonPage, IonRefresher, IonRefresherContent,
  withIonLifeCycle} from '@ionic/react';
import React from 'react';
import {API, Auth} from 'aws-amplify';
import ProgressUpdate from '../types/ProgressUpdate';
import MenuBar from '../components/MenuBar';
import UpdateCard from '../components/UpdateCard';
import UpdateCardSkeleton from '../components/UpdateCardSkeleton';

interface ProgressFeedProps {
  history
}
type ProgressFeedState = {
  updates?: ProgressUpdate[],
  user?: any
}

class ProgressFeed extends React.Component<ProgressFeedProps, ProgressFeedState>{

  constructor(props: ProgressFeedProps) {
    super(props);

    this.doRefresh = this.doRefresh.bind(this);

    this.state = {
      updates: [],
    }
  }

  async ionViewWillEnter() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({user: user});
      })
      .catch(err => {});

    this.doRefresh(null);
  }

  async doRefresh(event) {
    let response = await API.get('apic5763994', '/puzzles/updates', {});
    var allUpdates = [];
    for (let u of response) {
      const update = {
        userId: u.userId,
        userDisplay: u.userDisplay,
        updateId: u.updateId,
        buildId: u.buildId,
        date: u.date,
        puzzleId: u.puzzleId,
        puzzleName: u.puzzleName,
        percentComplete: u.percentComplete,
        comments: (u.status === "Started" ? u.description : u.comments),
        status: u.status,
        likes: u.likes,
        thread: u.thread
      };
      allUpdates.push(update);
    }
    this.setState({updates : allUpdates});

    // close the ionic event after pull-refresh
    if (event && event !== '')
      event.detail.complete();
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <MenuBar active="home" />
        </IonHeader>
        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={this.doRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          {this.state.updates.length === 0 && (
            <>
              <UpdateCardSkeleton />
              <UpdateCardSkeleton />
            </>
          )}
          {this.state.updates.map((update, index) => (
            <UpdateCard key={update.updateId} history={this.props.history} user={this.state.user} update={update} />
          ))}
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(ProgressFeed);
