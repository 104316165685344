import {
  IonMenuButton, IonButtons, IonContent, IonHeader, IonPage,
  IonTitle, IonToolbar, IonButton, IonGrid, IonRow,
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle,
  IonCardContent, IonBadge,
  withIonLifeCycle} from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import { API, Auth } from 'aws-amplify';
import User from '../types/User';
import ProgressUpdate from '../types/ProgressUpdate';
import JitUserAvatar from '../components/JitUserAvatar';
import UpdateCard from '../components/UpdateCard';
import MenuBar from '../components/MenuBar';
import UserProfileCardSkeleton from '../components/UserProfileCardSkeleton';
import UpdateCardSkeleton from '../components/UpdateCardSkeleton';
import './UserProfile.css';

interface UserProfileProps extends RouteComponentProps<{id: string}> {
  history
}
type UserProfileState = {
  user?: User,
  loggedInUser?: any,
  joinDateFormatted?: string,
  numPuzzles?: number,
  numUpdates?: number,
  numPieces?: number,
  updates?: any
}

class UserProfile extends React.Component<UserProfileProps, UserProfileState>{

  constructor(props: UserProfileProps) {
    super(props);

    this.signOut = this.signOut.bind(this);

    this.state = {
    }
  }

  async ionViewWillEnter() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({loggedInUser: user});
      })
      .catch(err => {});

    const userId = this.props.match.params.id;
    let response = await API.get('api2d19afaf', '/users/' + userId, {});
    var matchedUser = response[0];
    const u:User = {
      userId: matchedUser.userId,
      displayName: matchedUser.displayName,
      joinDate: matchedUser.joinDate
    };

    const options = { month: "long", year: "numeric" };
    const joinDateString = new Intl.DateTimeFormat("en-US", options).format(matchedUser.joinDate);
    this.setState({user : u, joinDateFormatted: joinDateString});

    const params = "?userId=" + u.userId;
    let updatesResp = await API.get('apic5763994', '/puzzles/updates' + params, {});
    var allUpdates = [];
    var latestUpdates = new Map();
    var numUpdates = 0;
    for (let u of updatesResp) {
      const update:ProgressUpdate = {
        userId: u.userId,
        userDisplay: u.userDisplay,
        updateId: u.updateId,
        buildId: u.buildId,
        date: u.date,
        puzzleId: u.puzzleId,
        puzzleName: u.puzzleName,
        percentComplete: u.percentComplete,
        comments: u.comments,
        status: u.status,
        numPieces: u.numPieces
      };

      // track all updates
      allUpdates.push(update);

      // track latest of each build for calculations
      if (update.status === "Added")
        continue;
      numUpdates++;
      if(!latestUpdates.has(update.buildId)) {
        latestUpdates.set(update.buildId, update);
      } else if (update.date > latestUpdates.get(update.buildId).date) {
        latestUpdates.set(update.buildId, update);
      }
    }

    const numPuzzles = latestUpdates.size;
    var numPieces = 0;
    for (let u of Array.from(latestUpdates.values())) {
      if (u.percentComplete)
        numPieces += Math.round(u.numPieces * u.percentComplete * .01);
    }
    this.setState({
      numPuzzles: numPuzzles,
      numUpdates: numUpdates,
      numPieces: numPieces
    })

    allUpdates.sort((a, b) => (a.date < b.date) ? 1 : -1)
    this.setState({updates : allUpdates});
  }

  signOut() {
    Auth.signOut().then(() => {
      this.props.history.push('/');
    }).catch(e => {
      console.log(e);
    });
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <MenuBar />
        </IonHeader>
        <IonContent>
          {this.state.user
          ?
            <IonCard>
              <IonCardHeader>
                <IonGrid>
                  <IonRow>
                    <JitUserAvatar userId={this.state.user.userId} />
                    <IonGrid>
                      <IonRow>
                        <IonCardTitle>
                          {this.state.user.displayName}
                        </IonCardTitle>
                      </IonRow>
                      <IonRow>
                        <IonCardSubtitle>
                          Member since {this.state.joinDateFormatted}
                        </IonCardSubtitle>
                      </IonRow>
                    </IonGrid>
                  </IonRow>
                </IonGrid>
              </IonCardHeader>
              <IonCardContent>
                <IonGrid>
                  <IonRow>
                    <div className="stat-badge-container"><IonBadge className="stat-badge" color="primary">{this.state.numPuzzles}</IonBadge>Puzzles Worked</div>
                    <div className="stat-badge-container"><IonBadge className="stat-badge" color="secondary">{this.state.numUpdates}</IonBadge>Updates Logged</div>
                    <div className="stat-badge-container"><IonBadge className="stat-badge" color="tertiary">{this.state.numPieces}</IonBadge>Total Pieces</div>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
          :
            <UserProfileCardSkeleton />
          }

          <h4 className="page-subheader">User Timeline</h4>

          {!this.state.updates &&
            <UpdateCardSkeleton />
          }
          {this.state.updates && this.state.updates.map((update, index) => (
            <UpdateCard key={update.updateId} update={update} />
          ))}
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(UserProfile);
