import {
  IonMenuButton, IonButtons, IonContent, IonHeader, IonPage,
  IonTitle, IonToolbar, IonItem, IonLabel, IonInput,
  IonGrid, IonRow,  IonButton, IonText, IonNote,
  IonModal } from '@ionic/react';
import React from 'react';
import { Auth } from 'aws-amplify';
import './SignIn.css';
import UserAvatar from '../components/UserAvatar';
import MenuBar from '../components/MenuBar';

type SignInProps = {
  history
};
type SignInState = {
  email: string,
  displayName: string,
  password: string,
  isLoading: boolean,
  showSuccessModal: boolean,
  awsUserId?: string
}

class SignIn extends React.Component<SignInProps, SignInState>{

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.handleKey = this.handleKey.bind(this);
    this.handleError = this.handleError.bind(this);

    this.state = {
      email: '',
      displayName: '',
      password: '',
      isLoading: false,
      showSuccessModal: false
    };
  }

  /* submit to AWS */
  async submit(event: any) {
    event.preventDefault();
    this.setState({isLoading: true});

    if (!(this.state.email && this.state.password)) {
      alert('All fields are required; try again.');
      this.setState({isLoading: false});
      return;
    }

    Auth.signIn(this.state.email, this.state.password)
      .then(user => {
        this.setState({
          awsUserId: user.username,
          showSuccessModal: true});
        console.log(user)
        setTimeout(() => {
          this.props.history.push('/');
          this.setState({showSuccessModal: false})
        }, 3000);

      })
      .catch(err => this.handleError("Error signing in", err));
  }

  handleKey(e) {
    if (e.key === "Enter") {
      this.submit(e);
    }
  }

  handleError(msg, err) {
    this.setState({isLoading: false});
    alert(msg);
    console.log(err);
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <MenuBar />
        </IonHeader>
        <IonContent>
          <IonItem>
            <IonLabel position="stacked">Email Address</IonLabel>
            <IonInput type="email" name="email" value={this.state.email} onIonChange={(e: Event) => this.setState({email: (e.target as HTMLInputElement).value})} ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Password</IonLabel>
            <IonInput type="password" name="password" value={this.state.password} onKeyUp={this.handleKey} onIonChange={(e: Event) => this.setState({password: (e.target as HTMLInputElement).value})} ></IonInput>
          </IonItem>

          <IonGrid><IonRow>
            <IonButton onClick={this.submit}>
              Sign In
            </IonButton>
          </IonRow></IonGrid>

          <IonItem lines="none"><IonNote>
            <a href="/forgot">Forgot Password?</a>
          </IonNote></IonItem>

          <IonModal cssClass="auto-height" isOpen={this.state.showSuccessModal}>
            <IonHeader><IonToolbar><IonTitle>Success!</IonTitle></IonToolbar></IonHeader>
            <div className="modal-content">
              <IonGrid>
                <IonRow class="ion-justify-content-center ion-align-items-center">
                  {this.state.awsUserId && (
                    <UserAvatar userId={this.state.awsUserId} />
                  )}
                  {this.state.awsUserId && (
                    <IonText>Welcome back! Sending you back home... </IonText>
                  )}
                </IonRow>
              </IonGrid>
            </div>
          </IonModal>
        </IonContent>
      </IonPage>
    );
  }
}

export default SignIn;
