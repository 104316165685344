import {
  IonChip, IonIcon, IonLabel, IonPopover,
  IonItem } from '@ionic/react';
import React from 'react';
import UserAvatar from './UserAvatar';
import { chevronUpCircleOutline } from 'ionicons/icons';
import './LikedByChip.css';

type LikedByChipProps = {
  likes?: any
}
type LikedByChipState = {
  showPopover: boolean,
  popoverEvent?: any
}

class LikedByChip extends React.Component<LikedByChipProps, LikedByChipState>{

  constructor(props) {
    super(props);

    this.doShowPopover = this.doShowPopover.bind(this);
    this.doDismissPopover = this.doDismissPopover.bind(this);

    this.state = {
      showPopover: false
    }
  }

  doShowPopover(e) {
    this.setState({
      showPopover: true,
      popoverEvent: e.nativeEvent
    });
  }

  doDismissPopover() {
    this.setState({
      showPopover: false,
      popoverEvent: null
    });
  }

  render() {
    return (
      <>
        {this.props.likes && this.props.likes.length > 0 && (
          <>
            <IonChip color="primary" onClick={(e) => this.doShowPopover(e)}>
              <IonIcon icon={chevronUpCircleOutline} />
              <IonLabel>{this.props.likes.length}</IonLabel>
            </IonChip>
            <IonPopover event={this.state.popoverEvent} isOpen={this.state.showPopover} onDidDismiss={this.doDismissPopover}>
              {this.props.likes.map((like, index) => (
                <IonItem detail key={index} href={"/user/" + like.likedBy} onClick={(e) => this.doDismissPopover}>
                  <UserAvatar mode="in-popover" userId={like.likedBy} />
                  <IonLabel>{like.likedByDisplay}</IonLabel>
                </IonItem>
              ))}
            </IonPopover>
          </>
        )}
      </>
    );
  }
}

export default LikedByChip;
