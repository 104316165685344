import {
  IonHeader, IonModal, IonItem, IonIcon,
  IonToolbar, IonTitle, IonList, IonAvatar,
  IonLabel } from '@ionic/react';
import React from 'react';
import { barChart, home } from 'ionicons/icons';

type BuildStartedModalProps = {
  buildId?: number,
  isOpen: boolean,
  dismissEvent?: any
}

type BuildStartedModalState = {
}

class BuildStartedModal extends React.Component<BuildStartedModalProps, BuildStartedModalState>{

  render() {
    return (
      <IonModal cssClass="auto-height" isOpen={this.props.isOpen} onDidDismiss={this.props.dismissEvent}>
        <IonHeader><IonToolbar><IonTitle>Good luck; have fun</IonTitle></IonToolbar></IonHeader>
        <div className="modal-content">
          <IonList>
            <IonItem>
              Congrats on starting a new puzzle! Time to post an initial progress update?
            </IonItem>
            <IonItem routerDirection="root" routerLink={"/add-status/" + this.props.buildId} onClick={() => this.props.dismissEvent()}>
              <IonAvatar class="avatar-center">
                <IonIcon icon={barChart} />
              </IonAvatar>
              <IonLabel>
                Let's do it
                <p>Snap a pic, log some progress</p>
              </IonLabel>
            </IonItem>
            <IonItem routerDirection="root" routerLink="/" onClick={() => this.props.dismissEvent()}>
              <IonAvatar class="avatar-center">
                <IonIcon icon={home} />
              </IonAvatar>
              <IonLabel>
                Maybe later
                <p>Take me home</p>
              </IonLabel>
            </IonItem>
          </IonList>
        </div>
      </IonModal>
    );
  }
}

export default BuildStartedModal;
