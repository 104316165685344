import {
  IonMenuButton, IonButtons, IonContent, IonHeader, IonPage,
  IonTitle, IonToolbar, IonItem, IonLabel, IonInput,
  IonGrid, IonRow,  IonButton, IonText, IonList,
  IonModal, IonLoading } from '@ionic/react';
import React from 'react';
import { Auth } from 'aws-amplify';
import MenuBar from '../components/MenuBar';
import './SignIn.css';

type ForgotProps = {
  history
};
type ForgotState = {
  email: string,
  code: string,
  password1: string,
  password2: string,
  isLoading: boolean,
  showResetModal: boolean,
  showSuccessModal: boolean
}

class Forgot extends React.Component<ForgotProps, ForgotState>{

  codeInput: any;

  constructor(props) {
    super(props);

    this.codeInput = React.createRef();
    this.submit = this.submit.bind(this);
    this.handleError = this.handleError.bind(this);
    this.submitReset = this.submitReset.bind(this);
    this.completeReset = this.completeReset.bind(this);

    this.state = {
      email: '',
      code: '',
      password1: '',
      password2: '',
      isLoading: false,
      showResetModal: false,
      showSuccessModal: false
    };
  }

  /* submit to AWS */
  async submit(event: any) {
    event.preventDefault();
    this.setState({isLoading: true});

    if (!(this.state.email)) {
      alert('Email is required; try again.');
      this.setState({isLoading: false});
      return;
    }

    Auth.forgotPassword(this.state.email)
      .then(data => {
        console.log(data);
        this.setState({isLoading: false, showResetModal: true});
      })
      .catch(err => this.handleError("Error requesting reset", err));
  }

  async submitReset() {
    this.setState({isLoading: true});

    if (!(this.state.code && this.state.password1 && this.state.password2)) {
      alert('All fields are required; try again.');
      this.setState({isLoading: false});
      return;
    } else if (this.state.password1 !== this.state.password2) {
      alert('Passwords do not match; try again.');
      this.setState({isLoading: false});
      return;
    }

    Auth.forgotPasswordSubmit(this.state.email, this.state.code, this.state.password1)
      .then(data => {
        this.completeReset(data);
      })
      .catch(err => this.handleError("Error resetting password", err));

  }

  completeReset(data) {
    console.log(data);
    this.setState({showResetModal: false});
    Auth.signIn(this.state.email, this.state.password1)
      .then(user => {
        this.setState({showSuccessModal: true, isLoading: false})
        setTimeout(() => {
          this.props.history.push('/');
          this.setState({showSuccessModal: false})
        }, 3000);
      })
      .catch(err => this.handleError("Error finalizing reset", err));
  }

  handleError(msg, err) {
    this.setState({isLoading: false});
    alert(msg);
    console.log(err);
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <MenuBar />
        </IonHeader>
        <IonContent>
          <IonItem>
            <IonLabel position="stacked">Email Address</IonLabel>
            <IonInput type="email" name="email" value={this.state.email} onIonChange={(e: Event) => this.setState({email: (e.target as HTMLInputElement).value})} ></IonInput>
          </IonItem>

          <IonGrid><IonRow>
            <IonButton onClick={this.submit}>
              Reset Password
            </IonButton>
          </IonRow></IonGrid>

          <IonModal cssClass="auto-height"
            isOpen={this.state.showResetModal}
            backdropDismiss={false}
            onDidPresent={() => this.codeInput.current.setFocus()} >
            <IonHeader><IonToolbar><IonTitle>Reset Password</IonTitle></IonToolbar></IonHeader>
            <div className="modal-content">
              <IonList>
                <IonItem>
                  <IonLabel position="stacked">We sent you an email; enter the verification code from your inbox:</IonLabel>
                  <IonInput placeholder="Enter code here" ref={this.codeInput} name="code" value={this.state.code} onIonChange={(e: Event) => this.setState({code: (e.target as HTMLInputElement).value})} ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">New Password</IonLabel>
                  <IonInput type="password" name="password1" value={this.state.password1} onIonChange={(e: Event) => this.setState({password1: (e.target as HTMLInputElement).value})} ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Verify New Password</IonLabel>
                  <IonInput type="password" name="password2" value={this.state.password2} onIonChange={(e: Event) => this.setState({password2: (e.target as HTMLInputElement).value})} ></IonInput>
                </IonItem>
              </IonList>
              <IonGrid><IonRow class="ion-justify-content-center">
                <IonButton onClick={this.submitReset}>Finish Reset</IonButton>
              </IonRow></IonGrid>
            </div>
          </IonModal>

          <IonModal cssClass="auto-height" isOpen={this.state.showSuccessModal}>
            <IonHeader><IonToolbar><IonTitle>Success!</IonTitle></IonToolbar></IonHeader>
            <div className="modal-content">
              <IonGrid>
                <IonRow class="ion-justify-content-center ion-align-items-center">
                  <IonText>You're back in the game. Sending you home...</IonText>
                </IonRow>
              </IonGrid>
            </div>
          </IonModal>

          <IonLoading isOpen={this.state.isLoading} />
        </IonContent>
      </IonPage>
    );
  }
}

export default Forgot;
