import {
  IonImg, IonAvatar } from '@ionic/react';
import React from 'react';
import { Storage } from 'aws-amplify';
import './UserAvatar.css';

type UserAvatarProps = {
  userId: string,
  mode?: string,
  userLink?: boolean,
  onClick?: any
}
type UserAvatarState = {
  src?: string;
}

class UserAvatar extends React.Component<UserAvatarProps, UserAvatarState>{

  public static defaultProps = {
    mode: "normal",
    userLink: false
  };

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  async componentDidMount() {
    Storage.get('avatars/' + this.props.userId)
      .then((result: string) => (this.setState({src : result})))
      .catch(err => console.log(err));
  }

  render() {
    return (
      <>
        {this.props.userLink
        ?
          <a href={"/user/" + this.props.userId}>
            <IonAvatar className={this.props.mode}>
              <IonImg src={this.state.src || ""} />
            </IonAvatar>
          </a>
        :
          <IonAvatar className={this.props.mode} onClick={this.props.onClick}>
            <IonImg src={this.state.src || ""} />
          </IonAvatar>
        }
      </>
    );
  }
}

export default UserAvatar;
