import {
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle,
  IonCardContent } from '@ionic/react';
import React from 'react';
import TimeAgo from 'react-timeago';
import ProgressUpdate from '../types/ProgressUpdate';
import './UpdateCard.css';

type HomeBuildCardProps = {
  update: ProgressUpdate
}
type HomeBuildCardState = {
  href: string
}

class HomeBuildCard extends React.Component<HomeBuildCardProps, HomeBuildCardState>{

  constructor(props) {
    super(props);

    this.state = {
      href : "/add-status/" + this.props.update.updateId
    }
  }

  render() {
    return (
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Building: {this.props.update.puzzleName}</IonCardTitle>
          <IonCardSubtitle>
            {this.props.update.percentComplete && this.props.update.percentComplete > 0 && (
              <React.Fragment>
                {this.props.update.percentComplete}% Progress | <TimeAgo date={this.props.update.date} />
              </React.Fragment>
            )}
            {!this.props.update.percentComplete && (
              <React.Fragment>
                No Progress Yet | Started <TimeAgo date={this.props.update.date} />
              </React.Fragment>
            )}
          </IonCardSubtitle>
          <IonCardSubtitle>
            <a href={this.state.href}>Log Progress</a>
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
        </IonCardContent>
      </IonCard>
    );
  }
}

export default HomeBuildCard;
