import {
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle,
  IonCardContent, IonModal,
  IonGrid, IonRow, IonButton } from '@ionic/react';
import React from 'react';
import { API } from 'aws-amplify';
import PhotoSeries from './PhotoSeries';

type PuzzleModalProps = {
  puzzleId?: number,
  isOpen: boolean,
  dismissEvent: any,
  buildStarted: any,
  user?: any
}

type PuzzleModalState = {
  name?: string,
  numPieces?: number,
  publisher?: string,
  description?: string,
  addedByDisplay?: string,
  addedDate?: number,
  buildId?: number
}

class PuzzleModal extends React.Component<PuzzleModalProps, PuzzleModalState>{

  constructor(props) {
    super(props);

    this.startBuild = this.startBuild.bind(this);

    this.state = {};
  }

  /* once in view, grab puzzle data and images */
  async componentDidMount() {
    API.get('apic5763994', '/puzzles/' + this.props.puzzleId, {})
      .then(resp => {
        this.setState({
          name: resp[0].name,
          numPieces: resp[0].numPieces,
          publisher: resp[0].publisher,
          description: resp[0].description,
          addedByDisplay: resp[0].addedByDisplay || resp[0].addedBy,
          addedDate: resp[0].addedDate
        });
      })
      .catch(err => {
        console.log("Failed to fetch puzzle info: " + err);
      });
  }

  /* submit a startBuild 'event' to AWS */
  async startBuild() {

    const buildId = Number(Date.now() + "" + Math.floor((Math.random() * 900) + 100));
    const status = "Started";

    API.post('apic5763994', '/puzzles/updates', {
      body: {
        type: "U",
        buildId: buildId,
        updateId: buildId,
        userId: this.props.user.username,
        userDisplay: this.props.user.attributes['custom:displayName'],
        puzzleId: this.props.puzzleId,
        puzzleName: this.state.name,
        numPieces: this.state.numPieces,
        description: this.state.description,
        status: status,
        date: Date.now()
      }
    }).then(resp => {
      this.props.dismissEvent();
      this.props.buildStarted(buildId);
    })
    .catch(err => console.log(err));
  }

  render() {
    return (
      <IonModal cssClass="auto-height" isOpen={this.props.isOpen} onDidDismiss={this.props.dismissEvent} >
        <div className="modal-content">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>{this.state.name}</IonCardTitle>
              <IonCardSubtitle>{this.state.numPieces} Pieces | Published by {this.state.publisher}</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid>
                <IonRow>
                  <PhotoSeries storageRoot={this.props.puzzleId + '/base/'} />
                </IonRow>
              </IonGrid>
              {this.state.description}
            </IonCardContent>
          </IonCard>
          <IonGrid>
            <IonRow class="ion-justify-content-center ion-align-items-center">
              <IonButton disabled={!this.props.user} onClick={this.startBuild}>Start a Build</IonButton>
              <IonButton onClick={() => this.props.dismissEvent()}>Dismiss</IonButton>
            </IonRow>
          </IonGrid>
        </div>
      </IonModal>
    );
  }
}

export default PuzzleModal;
