import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import './PhotoPicker.css';
import Photo from '../types/Photo';

type PhotoPickerProps = {
  addPhotoToState: any,
  bindFileInput: any
}
type PhotoPickerState = {
}

class PhotoPicker extends React.Component<PhotoPickerProps, PhotoPickerState>{

  fileInput: any;

  constructor(props) {
    super(props);
    this.openPhotoPicker = this.openPhotoPicker.bind(this);
    this.capturePhoto = this.capturePhoto.bind(this);
    this.fileInput = React.createRef();
    this.props.bindFileInput(this.fileInput);
  }

  ionViewWillEnter() {
    this.props.bindFileInput(this.fileInput);
  }

  /* click the input element */
  openPhotoPicker(event: any) {
    event.preventDefault();
    if (this.fileInput == null) {
      return;
    }
    this.fileInput.current.click();
  }

  /* store the photo after it's chosen */
  capturePhoto(event: any) {
    event.preventDefault();
    if (this.fileInput == null) {
      return;
    }

    const fileList: FileList = this.fileInput.current.files;

    if (fileList && fileList.length > 0) {
      this.firstFileToBase64(fileList[0]).then((result) => {
        const newPhoto = {
          file: fileList[0],
          filepath: fileList[0].name,
          webviewPath: result,
          type: fileList[0].type
        };
        this.props.addPhotoToState(newPhoto);
      }, (err: any) => {
        // Ignore error, do nothing
      });
    }
  }

  /* util method */
  private firstFileToBase64(fileImage: File): Promise<any> {
    return new Promise((resolve, reject) => {
      let fileReader: FileReader = new FileReader();
      if (fileReader && fileImage != null) {
        fileReader.readAsDataURL(fileImage);
        fileReader.onload = () => {
          resolve(fileReader.result ?? "");
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      } else {
        reject(new Error('No file found'));
      }
    });
  }

  render() {
    return (
      <div id="pwa-photo-div">
          <input type="file" id="pwa-photo" ref={this.fileInput} accept="image/*" onChange={this.capturePhoto} />
      </div>
    );
  }
}

export default PhotoPicker;
