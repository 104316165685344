import {
  IonMenuButton, IonButtons, IonContent, IonHeader, IonPage,
  IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonTextarea,
  IonFab, IonFabButton, IonIcon, IonGrid, IonRow, IonListHeader,
  IonImg, IonCol, IonToast, IonSpinner, IonModal, IonNote,
  IonList, IonSelect, IonSelectOption, IonText,
  withIonLifeCycle } from '@ionic/react';
import React from 'react';
import { withRouter } from "react-router";
import { API, Auth, Storage} from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { checkmark, camera } from 'ionicons/icons';
import './NewPuzzle.css';
import MenuBar from '../components/MenuBar';
import PhotoPicker from '../components/PhotoPicker';
import Photo from '../types/Photo';

type NewPuzzleState = {
  fileInputRef?: any,
  user?: any
  uuid: number,
  puzzleName: string,
  publisher: string,
  numPieces: any,
  description: string,
  photos: Photo[],
  updateId?: number,
  showToast: boolean,
  showNextStepModal: boolean,
  isLoading: boolean,
  toastText: string,
  progress: string,
  progressInfo?: string,
  nextStepInfo?: string
}

class NewPuzzle extends React.Component<any, NewPuzzleState>{

  constructor(props) {
    super(props);

    this.openPhotoPicker = this.openPhotoPicker.bind(this);
    this.capturePhoto = this.capturePhoto.bind(this);
    this.bindFileInput = this.bindFileInput.bind(this);
    this.submit = this.submit.bind(this);
    this.onSubmitFinished = this.onSubmitFinished.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.handleProgressChange = this.handleProgressChange.bind(this);
    this.createUpdate = this.createUpdate.bind(this);
    this.doRedirect = this.doRedirect.bind(this);

    this.state = {
      uuid: -1,
      puzzleName: "",
      publisher: "",
      numPieces: 0,
      description: "",
      photos: [],
      showToast: false,
      showNextStepModal: false,
      isLoading: false,
      toastText: "",
      progress: ""
    };
  }

  async ionViewWillEnter() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        console.log(user);
        this.setState({user: user});
      })
      .catch(err => {
        console.log(err);
      });
  }

  /* called from PhotoPicker when a photo is selected */
  capturePhoto(photo: Photo) {
    const newPhotos = [...this.state.photos, photo];
    this.setState({photos: newPhotos});
  }

  /* get a ref to the PhotoPicker input element */
  bindFileInput(fileInputRef: any) {
    this.setState({fileInputRef: fileInputRef});
  }

  /* use the ref within PhotoPicker to open photo dialog */
  openPhotoPicker(event: any) {
    event.preventDefault();
    if (this.state.fileInputRef == null) {
      return;
    }
    this.state.fileInputRef.current.click();
  }

  handleProgressChange(newValue: string) {
    this.setState({progress: newValue});
    var newInfo, newNext;
    if (newValue === "Added") {
      newInfo = "Thanks for adding to our catalog!";
      newNext = "Thanks for adding to our catalog - see you on the feed."
    } else if (newValue === "Started") {
      newInfo = "Great! We'll add this build to your Home page so you can log progress anytime.";
      newNext = "Good luck, have fun! Add puzzle progress anytime."
    } else if (newValue === "In Progress") {
      newInfo = "Great! You can log current progress and pics on the next screen.";
      newNext = "Now let's log your puzzle progress."
    } else if (newValue === "Complete") {
      newNext = "Now let's see that finished build."
      newInfo = "Woohoo! On the next screen, you can add any progress pics and a final build pic.";
    }
    this.setState({progressInfo: newInfo, nextStepInfo: newNext});
  }

  /* validate and submit to AWS */
  async submit(event: any) {
    if(!this.state.puzzleName || this.state.numPieces <= 0) {
      alert('Please include a name and number of pieces.');
      return;
    }
    if(!this.state.progress) {
      alert('Please provide your current status for this puzzle.');
      return;
    }
    if(this.state.photos.length === 0) {
      alert('Please add at least one photo for this puzzle.');
      return;
    }

    this.setState({ isLoading: true });

    const uuid = Number(Date.now() + "" + Math.floor((Math.random() * 900) + 100));
    const response = await API.post('apic5763994', '/puzzles', {
      body: {
        puzzleId: uuid,
        name: this.state.puzzleName,
        publisher: (this.state.publisher || undefined),
        numPieces: this.state.numPieces,
        description: (this.state.description || undefined),
        puzzleIndex: (this.state.puzzleName + '-' + this.state.publisher + '-' + this.state.numPieces).toLowerCase(),
        addedBy: this.state.user.username,
        addedByDisplay: this.state.user['custom:displayName'],
        addedDate: Date.now(),
        type: "P"
      }
    });
    this.setState({ uuid: uuid });

    const numPhotos = this.state.photos.length;
    var successPhotos = 0;
    var donePhotos = 0;

    this.state.photos.map((photo, i) => {
      var path = uuid + "/base/" + uuidv4();
      if (photo.filepath.lastIndexOf(".") > -1) {
        path += photo.filepath.substring(photo.filepath.lastIndexOf("."), photo.filepath.length);
      }
      Storage.put(path, photo.file, {contentType: photo.type})
        .then(result => {
          successPhotos++;
          if (numPhotos === ++donePhotos)
            this.onSubmitFinished(successPhotos, donePhotos);
        })
        .catch(err => {
          alert('Error uploading photo: ' + err);
          console.log(err);
          if (numPhotos === ++donePhotos)
            this.onSubmitFinished(successPhotos, donePhotos);
        });
    });
  }

  /* follow-on actions after initial submit to AWS */
  onSubmitFinished(numSuccess, numTotal) {
    this.setState({
      showToast: true,
      toastText: " " + numSuccess + " of " + numTotal + " photos uploaded."
    });

    this.createUpdate();
  }

  async createUpdate() {
    var buildId;
    var status = this.state.progress;
    const updateId = Number(Date.now() + "" + Math.floor((Math.random() * 900) + 100));
    if (this.state.progress !== "Added") {
      buildId = Number(Date.now() + "" + Math.floor((Math.random() * 900) + 100));
      status = "Started";
    }

    API.post('apic5763994', '/puzzles/updates', {
      body: {
        type: "U",
        buildId: (buildId || undefined),
        updateId: updateId,
        userId: this.state.user.username,
        userDisplay: this.state.user.attributes['custom:displayName'],
        puzzleId: this.state.uuid,
        puzzleName: this.state.puzzleName,
        numPieces: this.state.numPieces,
        description: (this.state.description || undefined),
        status: status,
        date: Date.now()
      }
    })
      .then(resp => {
        this.setState({
          updateId: updateId,
          isLoading: false,
          showNextStepModal: true});
          this.doRedirect();
        })
      .catch(err => {
        alert("Something went wrong: " + err);
        console.log(err);
      });
  }

  doRedirect() {
    var nextPage = "/feed";
    if (this.state.progress === "In Progress" || this.state.progress === "Complete") {
      nextPage = "/add-status/" + this.state.updateId;
    }
    setTimeout(() => {
      this.props.history.push(nextPage);
      this.setState({showNextStepModal: false})
    }, 3000);
  }

  /* select all text when entering a field */
  onFocus(e) {
    try {
      e.target.firstElementChild.select();
    } catch (err) {}
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <MenuBar />
        </IonHeader>
        <IonContent>
          <IonList>
            <IonListHeader lines="full">
              <IonLabel>
                <h2>Add a Puzzle</h2>
                <IonNote>
                  Provide basic puzzle info and box pictures.
                  Other users will be able to select this puzzle for their own builds in the future.
                </IonNote>
              </IonLabel>
            </IonListHeader>
            <IonItem>
              <IonLabel position="stacked">Puzzle Name</IonLabel>
              <IonInput name="puzzleName" value={this.state.puzzleName} onIonChange={(e: Event) => this.setState({puzzleName: (e.target as HTMLInputElement).value})} autocapitalize="sentences"></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Publisher</IonLabel>
              <IonInput name="publisher" value={this.state.publisher} onIonChange={(e: Event) => this.setState({publisher: (e.target as HTMLInputElement).value})} autocapitalize="sentences"></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked"># of Pieces</IonLabel>
              <IonInput name="numPieces" value={this.state.numPieces} inputmode="numeric" type="number"
                onIonChange={(e: Event) => this.setState({numPieces: (e.target as HTMLInputElement).value})}
                onIonFocus={(e: Event) => this.onFocus(e)}></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Description</IonLabel>
              <IonTextarea name="description" value={this.state.description} onIonChange={(e: Event) => this.setState({description: (e.target as HTMLInputElement).value})} maxlength={500} autocapitalize="sentences"></IonTextarea>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Are you currently building this puzzle?</IonLabel>
              <IonSelect value={this.state.progress} onIonChange={e => this.handleProgressChange(e.detail.value)} interface="popover">
                <IonSelectOption value="Added">No</IonSelectOption>
                <IonSelectOption value="Started">Yes, I'm just starting it.</IonSelectOption>
                <IonSelectOption value="In Progress">Yes, it's in progress.</IonSelectOption>
                <IonSelectOption value="Complete">I've just finished it.</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem className="item-text-wrap" lines="none">
              <IonText className="info-msg" color="tertiary">
                {this.state.progressInfo}<br /><br />
                Upload general puzzle pics (box pic, etc.) to help other users identify this puzzle. You can add individual progress pics on the next page.<br />
              </IonText>
            </IonItem>
          </IonList>
          <IonGrid>
            <IonRow>
              {this.state.photos.map((photo, index) => (
                <IonCol size="6" key={index}>
                  <IonImg src={photo.webviewPath} />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>

          <IonFab vertical="bottom" horizontal="start" slot="fixed">
            <IonFabButton onClick={this.openPhotoPicker}>
              <IonIcon icon={camera} />
            </IonFabButton>
          </IonFab>
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={this.submit}>
              {this.state.isLoading ? (
                <IonSpinner />
              ) : (
                <IonIcon icon={checkmark} />
              )}
            </IonFabButton>
          </IonFab>

          <IonToast
            isOpen={this.state.showToast}
            onDidDismiss={(e: Event) => this.setState({ showToast: false})}
            message={"Puzzle saved successfully." + this.state.toastText}
            position="bottom" duration={3000} />

          <PhotoPicker addPhotoToState={this.capturePhoto} bindFileInput={this.bindFileInput}/>

          <IonModal cssClass="auto-height" isOpen={this.state.showNextStepModal} backdropDismiss={false}>
            <IonHeader><IonToolbar><IonTitle>Success!</IonTitle></IonToolbar></IonHeader>
            <div className="modal-content">
              <IonGrid>
                <IonRow class="ion-justify-content-center ion-align-items-center">
                  <IonText>{this.state.nextStepInfo}</IonText>
                </IonRow>
              </IonGrid>
            </div>
          </IonModal>

        </IonContent>
      </IonPage>
    );
  }
}

export default withRouter(withIonLifeCycle(NewPuzzle));
