import {
  IonCard, IonAvatar, IonCardHeader, IonCardContent,
  IonGrid, IonRow, IonSkeletonText } from '@ionic/react';
import React from 'react';

class UserProfileCardSkeleton extends React.Component{

  render() {
    return (
      <IonCard>
        <IonCardHeader>
          <IonGrid>
            <IonRow>
              <IonAvatar>
                <IonSkeletonText />
              </IonAvatar>
              <IonGrid>
                <IonRow>
                  <IonSkeletonText animated style={{ width: '70%' }} />
                </IonRow>
                <IonRow>
                  <IonSkeletonText animated style={{ width: '50%' }} />
                </IonRow>
              </IonGrid>
            </IonRow>
          </IonGrid>
        </IonCardHeader>
        <IonCardContent>
          <IonSkeletonText animated style={{ width: '80%' }} />
          <IonSkeletonText animated style={{ width: '80%' }} />
        </IonCardContent>
      </IonCard>
    );
  }
}

export default UserProfileCardSkeleton;
