import {
  IonMenuButton, IonButtons, IonContent, IonHeader, IonPage,
  IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonTextarea,
  IonFab, IonFabButton, IonIcon, IonGrid, IonRow,
  IonImg, IonCol, IonToast, IonSpinner, IonRange,
  IonModal, IonText, IonAlert, withIonLifeCycle} from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import { API, Auth, Storage} from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { checkmark, camera } from 'ionicons/icons';
import './NewStatusUpdate.css';
import PhotoPicker from '../components/PhotoPicker';
import MenuBar from '../components/MenuBar';
import Photo from '../types/Photo';

interface NewStatusUpdateProps extends RouteComponentProps<{id: string}> {
  history
}
type NewStatusUpdateState = {
  fileInputRef?: any,
  user?: any,
  buildId?: number,
  puzzleId?: number,
  puzzleName: string,
  numPieces?: number,
  description?: string,
  lastPercentComplete?: any,
  percentComplete: any,
  comments: string,
  photos: Photo[],
  showToast: boolean,
  showSuccessModal: boolean,
  showFinishBuildAlert: boolean,
  isLoading: boolean,
  toastText: string;
}

class NewStatusUpdate extends React.Component<NewStatusUpdateProps, NewStatusUpdateState>{

  constructor(props: NewStatusUpdateProps) {
    super(props);

    this.openPhotoPicker = this.openPhotoPicker.bind(this);
    this.capturePhoto = this.capturePhoto.bind(this);
    this.bindFileInput = this.bindFileInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.doSubmit = this.doSubmit.bind(this);

    this.state = {
      puzzleName: "",
      percentComplete: 0,
      comments: "",
      photos: [],
      showToast: false,
      showSuccessModal: false,
      showFinishBuildAlert: false,
      isLoading: false,
      toastText: ""
    };
  }

  async ionViewWillEnter() {

    API.get('apic5763994', '/puzzles/updates/' + this.props.match.params.id, {})
      .then(resp => {
        console.log(resp);
        this.setState({
          buildId: resp[0].buildId,
          puzzleName: resp[0].puzzleName,
          puzzleId: resp[0].puzzleId,
          numPieces: resp[0].numPieces,
          description: resp[0].description,
          lastPercentComplete: resp[0].percentComplete
        });

        // default to the same pct as last update
        if(resp[0].percentComplete) {
          this.setState({
            percentComplete : Number(resp[0].percentComplete)
          });
        }
      })
      .catch(err => {
        console.log(err);
      });

    Auth.currentAuthenticatedUser()
      .then(user => {
        console.log(user);
        this.setState({user: user});
      })
      .catch(err => {
        console.log(err);
      });
  }

  /* called from PhotoPicker when a photo is selected */
  capturePhoto(photo: Photo) {
    const newPhotos = [...this.state.photos, photo];
    this.setState({photos: newPhotos});
  }

  /* get a ref to the PhotoPicker input element */
  bindFileInput(fileInputRef: any) {
    this.setState({fileInputRef: fileInputRef});
  }

  /* use the ref within PhotoPicker to open photo dialog */
  openPhotoPicker(event: any) {
    event.preventDefault();
    if (this.state.fileInputRef == null) {
      return;
    }
    this.state.fileInputRef.current.click();
  }

  /* submit handler */
  handleSubmit(event: any) {
    if (this.state.percentComplete >= 100) {
      this.setState({showFinishBuildAlert: true});
      return;
    }

    this.doSubmit();
  }

  /* submit to AWS */
  async doSubmit() {
    this.setState({ isLoading: true });

    const status = this.state.percentComplete === 100 ? "Complete" : "In Progress";
    const uuid = Number(Date.now() + "" + Math.floor(Math.random() * 1000));
    const response = await API.post('apic5763994', '/puzzles/updates', {
      body: {
        type: "U",
        buildId: this.state.buildId,
        updateId: uuid,
        userId: this.state.user.username,
        userDisplay: this.state.user.attributes['custom:displayName'],
        puzzleId: this.state.puzzleId,
        puzzleName: this.state.puzzleName,
        numPieces: this.state.numPieces,
        description: this.state.description,
        status: status,
        date: Date.now(),
        percentComplete: this.state.percentComplete,
        comments: (this.state.comments || undefined)
      }
    });

    const numPhotos = this.state.photos.length;
    var successPhotos = 0;
    var donePhotos = 0;

    if (numPhotos === 0)
      this.onSubmitFinished(0, 0);

    this.state.photos.map((photo, i) => {
      var path = this.state.puzzleId + "/updates/" + uuid + "/" + uuidv4();
      if (photo.filepath.lastIndexOf(".") > -1) {
        path += photo.filepath.substring(photo.filepath.lastIndexOf("."), photo.filepath.length);
      }
      Storage.put(path, photo.file, {contentType: photo.type})
        .then(result => {
          successPhotos++;
          if (numPhotos === ++donePhotos)
            this.onSubmitFinished(successPhotos, donePhotos);
        })
        .catch(err => {
          alert('Error uploading photo: ' + err);
          console.log(err);
          if (numPhotos === ++donePhotos)
            this.onSubmitFinished(successPhotos, donePhotos);
        });
    });
  }

  onSubmitFinished(numSuccess, numTotal) {
    this.setState({
      showToast: true,
      toastText: " " + numSuccess + " of " + numTotal + " photos uploaded.",
      isLoading: false,
      showSuccessModal: true
    });

    setTimeout(() => {
      this.props.history.push('/feed');
      this.setState({showSuccessModal: false})
    }, 3000);
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <MenuBar />
        </IonHeader>
        <IonContent>
          <IonItem>
            <IonLabel position="stacked">Puzzle Being Worked:</IonLabel>
            <IonInput name="puzzleName" value={this.state.puzzleName} disabled ></IonInput>
          </IonItem>
          <IonItem>
            <IonGrid className="pct-block">
              <IonRow>
                <IonLabel position="stacked">Percent Complete:</IonLabel><br />
              </IonRow>
              <IonRow>
                <IonRange name="percentComplete" value={this.state.percentComplete} onIonChange={(e: Event) => this.setState({percentComplete: (e.target as HTMLInputElement).value})} min={0} max={100} step={1} snaps={true} pin={true} ticks={false} >
                  <IonLabel slot="start"><span className="emoji-larger">🚫</span></IonLabel>
                  <IonLabel slot="end"><span className="emoji-larger">💯</span></IonLabel>
                </IonRange>
              </IonRow>
              <IonRow>
                <IonLabel position="stacked">Last Update: {this.state.lastPercentComplete && (
                  <React.Fragment>
                    {this.state.lastPercentComplete}% ≈ {Math.round(this.state.lastPercentComplete * .01 * this.state.numPieces)} pieces
                  </React.Fragment>
                )}
                {!this.state.lastPercentComplete && (
                  <React.Fragment>
                    n/a
                  </React.Fragment>
                )}
                </IonLabel>
              </IonRow>
              <IonRow>
                <IonLabel position="stacked">This Update: {this.state.percentComplete}% ≈ {Math.round(this.state.percentComplete * .01 * this.state.numPieces)} pieces</IonLabel>
              </IonRow>
              <br />
            </IonGrid>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">How's It Going?</IonLabel>
            <IonTextarea name="comments" value={this.state.comments} onIonChange={(e: Event) => this.setState({comments: (e.target as HTMLInputElement).value})} maxlength={500} autocapitalize="sentences"></IonTextarea>
          </IonItem>
          <IonGrid>
            <IonRow>
              {this.state.photos.map((photo, index) => (
                <IonCol size="6" key={index}>
                  <IonImg src={photo.webviewPath} />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>

          <IonFab vertical="bottom" horizontal="start" slot="fixed">
            <IonFabButton onClick={this.openPhotoPicker}>
              <IonIcon icon={camera} />
            </IonFabButton>
          </IonFab>
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={this.handleSubmit}>
              {this.state.isLoading ? (
                <IonSpinner />
              ) : (
                <IonIcon icon={checkmark} />
              )}
            </IonFabButton>
          </IonFab>

          <IonToast
            isOpen={this.state.showToast}
            onDidDismiss={(e: Event) => this.setState({ showToast: false})}
            message={"Update posted successfully." + this.state.toastText}
            position="bottom" duration={3000} />

          <PhotoPicker addPhotoToState={this.capturePhoto} bindFileInput={this.bindFileInput}/>

          <IonModal cssClass="auto-height" isOpen={this.state.showSuccessModal}>
            <IonHeader><IonToolbar><IonTitle>Success!</IonTitle></IonToolbar></IonHeader>
            <div className="modal-content">
              <IonGrid>
                <IonRow class="ion-justify-content-center ion-align-items-center">
                  Thanks for posting!
                </IonRow>
              </IonGrid>
            </div>
          </IonModal>

          <IonAlert isOpen={this.state.showFinishBuildAlert}
            header={'Finish this Build?'}
            message={'Submitting with 100% progress will mark this build as Completed.'}
            buttons={[
            {
              text: 'Go Back',
              role: 'cancel',
              handler: () => {
                this.setState({showFinishBuildAlert: false});
              }
            },
            {
              text: "Yes, I'm Done!",
              handler: () => {
                this.doSubmit();
              }
            }
          ]} />

        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(NewStatusUpdate);
