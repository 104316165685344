import {
  IonMenu, IonToolbar, IonHeader, IonTitle, IonList,
  IonContent, IonItem } from '@ionic/react';
import React from 'react';
import { Auth } from 'aws-amplify';

type UserMenuProps = {
}
type UserMenuState = {
  user?: any
}

class UserMenu extends React.Component<UserMenuProps, UserMenuState>{

  constructor(props) {
    super(props);

    this.doSignOut = this.doSignOut.bind(this);

    this.state = {
    }
  }

  async componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(user => this.setState({user: user}))
      .catch(err => {});
  }

  doSignOut() {
    Auth.signOut()
    .then(() => {})
    .catch(e => {
      console.log(e);
    });
  }

  render() {
    return (
      <IonMenu side="start" menuId="user" contentId="main">
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>Puzzfeed</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            {!this.state.user
            ? <>
                <IonItem routerLink="/register">Register</IonItem>
                <IonItem routerLink="/sign-in">Sign In</IonItem>
              </>
            :
              <>
                <IonItem routerLink={"/user/" + this.state.user.username}>My Profile</IonItem>
                <IonItem onClick={this.doSignOut} routerLink="/">Sign Out</IonItem>
              </>
            }
          </IonList>
        </IonContent>
      </IonMenu>
    );
  }
}

export default UserMenu;
