import {
  IonContent, IonHeader, IonPage, IonButton,
  IonItem, IonLabel, IonToast, IonSegment, IonSegmentButton,
  IonList, IonSearchbar, IonNote, IonRow, IonGrid,
  withIonLifeCycle} from '@ionic/react';
import React from 'react';
import { API, Auth} from 'aws-amplify';
import PhotoSeriesPreview from '../components/PhotoSeriesPreview';
import PuzzleModal from '../components/PuzzleModal';
import BuildStartedModal from '../components/BuildStartedModal';
import MenuBar from '../components/MenuBar';
import Puzzle from '../types/Puzzle';
import './PuzzleList.css';

interface PuzzleListProps {
  history
}
type PuzzleListState = {
  searchMode: string,
  searchKey: string,
  results: Puzzle[],
  resultsReady: boolean,
  user?: any,
  showModal: boolean,
  selectedPuzzleId?: number,
  showStartBuildModal: boolean,
  newBuildId?: number
}

class PuzzleList extends React.Component<PuzzleListProps, PuzzleListState>{

  constructor(props: PuzzleListProps) {
    super(props);

    this.onSearchModeChange = this.onSearchModeChange.bind(this);
    this.onSearchKeyChange = this.onSearchKeyChange.bind(this);
    this.openPuzzleModal = this.openPuzzleModal.bind(this);
    this.modalDismiss = this.modalDismiss.bind(this);
    this.gotBuildId = this.gotBuildId.bind(this);
    this.buildModalDismiss = this.buildModalDismiss.bind(this);
    this.doSearch = this.doSearch.bind(this);

    this.state = {
      searchMode: "all",
      searchKey: "",
      results: [],
      resultsReady: false,
      showModal: false,
      showStartBuildModal: false
    };
  }

  async ionViewWillEnter() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({user: user});
        this.doSearch();
      })
      .catch(err => {
        this.doSearch();
      });
  }

  onSearchModeChange(newValue) {
    let current = this.state.searchMode;
    if (current !== newValue) {
      this.setState({searchMode: newValue, results: [], resultsReady: false});
      this.doSearch();
    }
  }

  onSearchKeyChange(newValue) {
    let current = this.state.searchKey.toLowerCase();
    if (!newValue && current !== newValue.toLowerCase()) {
      this.setState({searchKey: "", results: [], resultsReady: false});
      this.doSearch();
    }
    if (newValue && current !== newValue.toLowerCase() && newValue.length > 2) {
      this.setState({searchKey: newValue, results: [], resultsReady: false});
      this.doSearch();
    }
  }

  openPuzzleModal(puzzleId: number) {
    this.setState({
      selectedPuzzleId: puzzleId,
      showModal: true
    });
  }

  modalDismiss() {
    this.setState({
      selectedPuzzleId: null,
      showModal: false,
    });
  }

  gotBuildId(buildId) {
    this.setState({showStartBuildModal: true, newBuildId: buildId});
  }

  buildModalDismiss() {
    this.setState({
      showStartBuildModal: false,
      newBuildId: null
    });
  }

  async doSearch() {
    var params = "";
    if (this.state.searchMode === 'mine') {
      params += "?type=me&userId=" + this.state.user.username;
    }
    if (this.state.searchKey) {
      params += ((params.length > 0 ? "&" : "?") + "search=" + this.state.searchKey.toLowerCase());
    }

    let response = await API.get('apic5763994', '/puzzles' + params, {});
    var results = [];
    for (let p of response) {
      const puzzle = {
        name: p.name,
        description: p.description,
        publisher: p.publisher,
        dateAdded: p.dateAdded,
        puzzleId: p.puzzleId,
        numPieces: p.numPieces
      };
      results.push(puzzle);
    }
    this.setState({results : results, resultsReady: true});
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <MenuBar />
        </IonHeader>
        <IonContent>
          <IonSegment value={this.state.searchMode} onIonChange={e => this.onSearchModeChange(e.detail.value)}>
            <IonSegmentButton value="all">
              <IonLabel>All</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="mine" disabled={!this.state.user} >
              <IonLabel>Added By Me</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {this.state.user &&
            <IonGrid><IonRow class="ion-justify-content-center">
              <IonButton href="/new-puzzle">Add a Puzzle</IonButton>
            </IonRow></IonGrid>
          }

          <IonSearchbar value={this.state.searchKey} onIonChange={e => this.onSearchKeyChange(e.detail.value)}></IonSearchbar>

          <IonList>
            {this.state.resultsReady && this.state.results.length === 0 && (
              <IonItem>No results found.</IonItem>
            )}
            {this.state.results.map((puzzle, index) => (
              <React.Fragment key={puzzle.puzzleId}>
                <IonItem button onClick={() => {this.openPuzzleModal(puzzle.puzzleId)}} detail>
                  <PhotoSeriesPreview puzzleId={puzzle.puzzleId} />
                  <IonLabel>{puzzle.name}</IonLabel>
                  <IonNote class="ion-text-right" slot="end" color="secondary">{puzzle.numPieces}</IonNote>
                </IonItem>
              </React.Fragment>
            ))}
          </IonList>

          {this.state.selectedPuzzleId && (
            <PuzzleModal
              isOpen={this.state.showModal}
              puzzleId={this.state.selectedPuzzleId}
              dismissEvent={this.modalDismiss}
              buildStarted={this.gotBuildId}
              user={this.state.user} />
          )}

          {this.state.user &&
            <IonToast
              isOpen={true}
              position="bottom"
              buttons={[{
                  text: 'OK',
                  role: 'cancel',
                }]}
              message="Choose the puzzle you want to start building, or click Add if your puzzle isn't here yet."
            />
          }
        </IonContent>

        {this.state.newBuildId && (
          <BuildStartedModal isOpen={this.state.showStartBuildModal} buildId={this.state.newBuildId} dismissEvent={this.buildModalDismiss} />
        )}
      </IonPage>
    );
  }
}

export default withIonLifeCycle(PuzzleList);
