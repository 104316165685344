import {
  IonRow, IonCol, IonLabel, IonPopover, IonItem,
  IonNote } from '@ionic/react';
import React from 'react';
import TimeAgo from 'react-timeago';
import UserAvatar from './UserAvatar';

type CommentThreadItemProps = {
  comment: any,
  userId?: any
}
type CommentThreadItemState = {
  showAvatarPopover: boolean,
  avatarPopoverEvent?: any
}

class CommentThreadItem extends React.Component<CommentThreadItemProps, CommentThreadItemState>{

  constructor(props) {
    super(props);

    this.doAvatarPopover = this.doAvatarPopover.bind(this);

    this.state = {
      showAvatarPopover: false
    }
  }

  doAvatarPopover(e) {
    this.setState({
      showAvatarPopover: true,
      avatarPopoverEvent: e.nativeEvent
    })
  }

  render() {
    return (
      <>
        <IonRow className="thread">
          {this.props.comment.by === this.props.userId
            ? <IonCol size="22">
                <div className="quote-content">{this.props.comment.msg}</div>
              </IonCol>
            : <IonCol offset="2" size="22" className="comment-row">
                <div className="div-comment">
                  <div className="quote-other">{this.props.comment.msg}</div>
                  <UserAvatar mode="thread" onClick={(e) => this.doAvatarPopover(e)} userId={this.props.comment.by} />
                </div>
              </IonCol>
          }
        </IonRow>
        <IonPopover
          onDidDismiss={() => this.setState({avatarPopoverEvent: null, showAvatarPopover: false})}
          event={this.state.avatarPopoverEvent}
          isOpen={this.state.showAvatarPopover}>
          <IonItem detail href={"/user/" + this.props.comment.by}>
            <IonLabel>
              <h2>{this.props.comment.byDisplay}</h2>
              <IonNote><TimeAgo date={this.props.comment.date}/></IonNote>
            </IonLabel>
          </IonItem>
        </IonPopover>
      </>
    );
  }
}

export default CommentThreadItem;
