import {
  IonImg, IonAvatar, IonBadge } from '@ionic/react';
import React from 'react';
import { Storage } from 'aws-amplify';
import './PhotoSeriesPreview.css';

type PhotoSeriesPreviewProps = {
  puzzleId: number
}
type PhotoSeriesPreviewState = {
  imgs: string[];
}

class PhotoSeriesPreview extends React.Component<PhotoSeriesPreviewProps, PhotoSeriesPreviewState>{

  constructor(props) {
    super(props);

    this.getPhotosForUpdate = this.getPhotosForUpdate.bind(this);

    this.state = {
      imgs: [],
    }
  }

  /* once in view, grab the images */
  async componentDidMount() {
    var root = this.props.puzzleId + '/base/';
    Storage.list(root)
      .then(result => this.getPhotosForUpdate(result))
      .catch(err => console.log(err));
  }

  /* get urls for each img in the update */
  getPhotosForUpdate(filenames) {
    for (let filename of filenames) {
      if (filename.size === 0) continue;
      const photoUrl = 'https://d1allaspwf7612.cloudfront.net';
      const imageParams = JSON.stringify({
        "bucket": "goodpuzzles2208d34531034b20a20278c1353d19dc223407-dev",
        "key": 'public/' + filename.key,
        "edits": {
          "resize": {
            "width": 150,
            "fit": "cover"
          },
          "withMetadata":true
        }
      });
      const url = photoUrl + '/' + btoa(imageParams);
      this.setState({imgs: [...this.state.imgs, url]});
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.imgs.map((img, index) => {
          if (index === 0 && this.state.imgs.length > 1) { return (
            <React.Fragment key={img}>
              <IonAvatar>
                <IonImg src={img} class="rounded" />
              </IonAvatar>
              <IonBadge className="badge-more">+{this.state.imgs.length - 1}</IonBadge>
            </React.Fragment>
          )} else if (index === 0) { return (
            <React.Fragment key={img}>
              <IonAvatar key={img}>
                <IonImg src={img} class="rounded" />
              </IonAvatar>
              <IonBadge className="badge-hide">+0</IonBadge>
            </React.Fragment>
          )}
        })}
      </React.Fragment>
    );
  }
}

export default PhotoSeriesPreview;
