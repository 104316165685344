import { IonImg, IonAvatar, IonGrid, IonRow, IonCol } from '@ionic/react';
import React from 'react';
import { Storage } from 'aws-amplify';
import ImgsViewer from 'react-images-viewer';

type PhotoGridProps = {
  storageRoot: string,
}
type PhotoGridState = {
  imgs: any,
  fullSizeImages : any[],
  currentImageIdx: number,
  isViewerOpen: boolean;
}

class PhotoGrid extends React.Component<PhotoGridProps, PhotoGridState>{

  constructor(props) {
    super(props);

    this.getPhotosForUpdate = this.getPhotosForUpdate.bind(this);
    this.openImageViewer = this.openImageViewer.bind(this);
    this.closeImageViewer = this.closeImageViewer.bind(this);
    this.viewerPrev = this.viewerPrev.bind(this);
    this.viewerNext = this.viewerNext.bind(this);

    this.state = {
      imgs: [],
      fullSizeImages: [],
      currentImageIdx: 0,
      isViewerOpen: false
    }
  }

  /* once in view, grab all images at the specified root */
  async componentDidMount() {
    Storage.list(this.props.storageRoot)
      .then(result => this.getPhotosForUpdate(result))
      .catch(err => console.log(err));
  }

  /* get urls for each img in the update */
  getPhotosForUpdate(filenames) {
    for (let filename of filenames) {
      if (filename.size === 0) continue;
      const photoUrl = 'https://d1allaspwf7612.cloudfront.net';
      const imageParams = JSON.stringify({
        "bucket": "goodpuzzles2208d34531034b20a20278c1353d19dc223407-dev",
        "key": 'public/' + filename.key,
        "edits": {
          "resize": {
            "width": 800,
            "fit": "cover"
          },
          "withMetadata":true
        }
      });
      const url = photoUrl + '/' + btoa(imageParams);
      const idx = this.state.imgs.length;
      var img = {
        scaledImg : url,
        s3key : filename.key,
        index : idx
      }
      this.setState({imgs: [...this.state.imgs, img]});

      Storage.get(img.s3key)
        .then((result:string) => {
          var fullSize = { src : result };
          var fsi = this.state.fullSizeImages;
          fsi[idx] = fullSize;
          this.setState({fullSizeImages: fsi});
        })
        .catch(err => console.log(err));
    }
  }

  openImageViewer(index) {
    this.setState({currentImageIdx : index, isViewerOpen : true});
  }

  closeImageViewer() {
    this.setState({currentImageIdx : 0, isViewerOpen : false});
  };

  viewerPrev() {
    const size = this.state.fullSizeImages.length;
    const curr = this.state.currentImageIdx;
    if (curr === 0) {
      this.setState({currentImageIdx : size - 1});
    } else {
      this.setState({currentImageIdx : curr - 1});
    }
  }

  viewerNext() {
    const size = this.state.fullSizeImages.length;
    const curr = this.state.currentImageIdx;
    if (curr === size - 1) {
        this.setState({currentImageIdx : 0});
    } else {
        this.setState({currentImageIdx : curr + 1});
    }
  }

  render() {
    return (
      <>
        <IonGrid><IonRow class="photo-grid-row">
          {this.state.imgs.map((img, index) => (
            <React.Fragment key={index}>
              {this.state.imgs.length === 1 &&
                <IonCol size="24" size-sm="16" size-md="14">
                  <IonImg src={img.scaledImg} onClick={ () => this.openImageViewer(index) } />
                </IonCol>
              }
              {this.state.imgs.length % 2 === 0 &&
                <IonCol size="24" size-sm="11" size-md="10">
                  <IonImg src={img.scaledImg} class="pg-multi" onClick={ () => this.openImageViewer(index) } />
                </IonCol>
              }
              {this.state.imgs.length % 3 === 0 &&
                <IonCol size="24" size-sm="8">
                  <IonImg src={img.scaledImg} class="pg-multi" onClick={ () => this.openImageViewer(index) } />
                </IonCol>
              }
            </React.Fragment>
          ))}
        </IonRow></IonGrid>

        <ImgsViewer
          imgs={this.state.fullSizeImages}
          isOpen={this.state.isViewerOpen}
          currImg={this.state.currentImageIdx}
          onClose={this.closeImageViewer}
          onClickPrev={this.viewerPrev}
          onClickNext={this.viewerNext}
          backdropCloseable={true}
          showImgCount={false}
        />
      </>
    );
  }
}

export default PhotoGrid;
