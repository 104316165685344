import {
  IonToolbar, IonButtons, IonButton, IonImg,
  IonMenuToggle, IonContent, IonItem } from '@ionic/react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCircle, faHome, faPuzzlePiece, faConciergeBell } from '@fortawesome/free-solid-svg-icons'
import { API, Auth } from 'aws-amplify';
import UserAvatar from './UserAvatar';
import './MenuBar.css';

type MenuBarProps = {
  active?: string
}
type MenuBarState = {
  user?: any,
  myBuildsVal?: string,
  notificationsVal?: number
}

class MenuBar extends React.Component<MenuBarProps, MenuBarState>{

  constructor(props) {
    super(props);

    this.loadHomeCards = this.loadHomeCards.bind(this);
    this.loadNotifications = this.loadNotifications.bind(this);

    this.state = {
    }
  }

  async componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({user: user})
        this.loadHomeCards();
        this.loadNotifications();
      })
      .catch(err => {
        this.loadHomeCards();
      });
  }

  async loadHomeCards() {
    if (this.state.user) {
      let response = await API.get('apic5763994', '/puzzles/updates?userId=' + this.state.user.username, {});
      var latestUpdates = new Map();
      var doneBuilds = [];
      for (let u of response) {
        const update = {
          buildId: u.buildId,
          updateId: u.updateId,
          date: u.date,
          status: u.status
        };

        if (update.status === "Added") {
          continue;
        }
        if (update.status === "Complete") {
          doneBuilds.push(update.buildId);
        }
        if (doneBuilds.includes(update.buildId)) {
          latestUpdates.delete(update.buildId);
          continue;
        }

        if(!latestUpdates.has(update.buildId)) {
          latestUpdates.set(update.buildId, update);
        } else if (update.date > latestUpdates.get(update.buildId).date) {
          latestUpdates.set(update.buildId, update);
        }
      }
      let updates = Array.from(latestUpdates.values());
      this.setState({myBuildsVal: "" + updates.length})
    } else {
      this.setState({myBuildsVal: "?"});
    }
  }

  async loadNotifications() {
    if (this.state.user) {
      let response = await API.get('apinotifications', '/notifications/' + this.state.user.username, {});
      this.setState({notificationsVal: response.length})
    }
  }

  render() {
    return (
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton>
            <IonMenuToggle menu="user">
              {this.state.user
              ?
                <UserAvatar userId={this.state.user.username} mode="menu"/>
              :
                <FontAwesomeIcon icon={faUser} />
              }
            </IonMenuToggle>
          </IonButton>
        </IonButtons>
        <IonButtons class="ion-justify-content-evenly">
          <IonButton href="/home">
            <IonImg src="./assets/puzz-01.png" id="header-logo" />
          </IonButton>
          <IonButton href="/my-builds">
            {this.props.active === 'my-builds'
            ?
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faPuzzlePiece} rotation={270} />
                <FontAwesomeIcon icon={faCircle} transform="shrink-12 right-3 down-13" className="active-marker" />
              </span>
            :
              <FontAwesomeIcon icon={faPuzzlePiece} rotation={270} />
            }
            {this.state.myBuildsVal &&
              <span className="fa-layers fa-fw counter-span">
                <span className="fa-layers-counter fa-layers-bottom-left builds-counter">{this.state.myBuildsVal}</span>
              </span>
            }
          </IonButton>
          <IonButton href="/notifications" disabled={!this.state.user}>
            {this.props.active === 'notifications'
            ?
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faConciergeBell} />
                <FontAwesomeIcon icon={faCircle} transform="shrink-12 down-12" className="active-marker"/>
              </span>
            :
              <FontAwesomeIcon icon={faConciergeBell} />
            }
            {this.state.notificationsVal > 0 &&
              <span className="fa-layers fa-fw counter-span">
                <span className="fa-layers-counter fa-layers-bottom-left notifications-counter">{this.state.notificationsVal}</span>
              </span>
            }
          </IonButton>
        </IonButtons>
      </IonToolbar>
    );
  }
}

export default MenuBar;
