import {
  IonImg, IonAvatar } from '@ionic/react';
import React from 'react';
import { Storage } from 'aws-amplify';
import './UserAvatar.css';

type JitUserAvatarProps = {
  userId: string
}
type JitUserAvatarState = {
  src?: string;
}

class JitUserAvatar extends React.Component<JitUserAvatarProps, JitUserAvatarState>{

  themes: string[];

  constructor(props) {
    super(props);

    this.verifyExists = this.verifyExists.bind(this);
    this.uploadToS3 = this.uploadToS3.bind(this);

    this.themes = [
      "sugarsweets", "heatwave", "daisygarden", "seascape",
      "summerwarmth", "bythepool", "duskfalling", "frogideas",
      "berrypie" ];

    this.state = {

    };
  }

  async componentDidMount() {
    Storage.list('avatars/' + this.props.userId)
      .then(result => (this.verifyExists(result, this.props.userId)))
      .catch(err => console.log(err));
  }

  verifyExists(resultList, userId) {
    if (resultList && resultList.length > 0) {
      Storage.get('avatars/' + this.props.userId)
        .then((result: string) => (this.setState({src : result})))
        .catch(err => console.log(err));
    } else {
      var theme = this.themes[Math.floor(Math.random() * 9)];
      var proxyUrl = 'https://floating-castle-26984.herokuapp.com/'
      var targetUrl = 'https://www.tinygraphs.com/labs/squares/random?theme=' + theme + '&numcolors=4'
      fetch(proxyUrl + targetUrl, { method: 'GET' })
        .then(response => response.blob())
        .then(data => this.uploadToS3(data, this.props.userId) );
    }
  }

  uploadToS3(avatar, userId) {
    Storage.put('avatars/' + userId, avatar)
      .then(result => this.verifyExists(["go"], userId))
      .catch(err => console.log(err));
  }

  render() {
    return (
      <IonAvatar>
        <IonImg src={this.state.src || ""} class="user-avatar" />
      </IonAvatar>
    );
  }
}

export default JitUserAvatar;
