import {
  IonCard, IonAvatar, IonCardHeader, IonCardSubtitle,
  IonCardContent, IonGrid, IonRow, IonSkeletonText } from '@ionic/react';
import React from 'react';

class UpdateCardSkeleton extends React.Component{

  render() {
    return (
      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>
            <IonSkeletonText animated style={{ width: '50%' }} />
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonAvatar>
                <IonSkeletonText />
              </IonAvatar>
              <IonAvatar>
                <IonSkeletonText />
              </IonAvatar>
            </IonRow>
          </IonGrid>
          <IonSkeletonText animated style={{ width: '70%' }} />
          <IonSkeletonText animated style={{ width: '40%' }} />
          <IonSkeletonText animated style={{ width: '40%' }} />
        </IonCardContent>
      </IonCard>
    );
  }
}

export default UpdateCardSkeleton;
