import {
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle,
  IonCardContent, IonGrid, IonRow, IonCol, IonChip, IonToast,
  IonIcon, IonLabel, IonPopover, IonItem, IonTextarea,
  IonButton, IonNote, IonRouterLink } from '@ionic/react';
import React from 'react';
import {API} from 'aws-amplify';
import TimeAgo from 'react-timeago';
import ProgressUpdate from '../types/ProgressUpdate';
import UserAvatar from './UserAvatar';
import PhotoSeries from './PhotoSeries';
import PhotoGrid from './PhotoGrid';
import LikedByChip from './LikedByChip';
import CommentThreadItem from './CommentThreadItem';
import { chevronUpCircle, chevronUpCircleOutline, chatboxOutline } from 'ionicons/icons';
import './UpdateCard.css';

type UpdateCardProps = {
  history?,
  update: ProgressUpdate,
  user?: any,
  featured?: boolean
}
type UpdateCardState = {
  storageRoot: string,
  showCommentPopover: boolean,
  commentPopoverEvent?: any,
  commentPopoverVal?: string,
  likes: any,
  userHasLiked?: boolean,
  isLoading: boolean,
  showToast: boolean,
  toastMsg?: string,
  commentThread?: any,
}

class UpdateCard extends React.Component<UpdateCardProps, UpdateCardState>{

  public static defaultProps = {
    featured: false
  };

  constructor(props) {
    super(props);

    this.doCommentClick = this.doCommentClick.bind(this);
    this.doCommentDismiss = this.doCommentDismiss.bind(this);
    this.doCommentSubmit = this.doCommentSubmit.bind(this);
    this.doLike = this.doLike.bind(this);
    this.doSubmitNotification = this.doSubmitNotification.bind(this);

    // calculate display variables
    var root = "";
    if (this.props.update.status === "Added" || this.props.update.status === "Started") {
      root = this.props.update.puzzleId + '/base/';
    } else {
      root = this.props.update.puzzleId + '/updates/' + this.props.update.updateId + '/';
    }

    this.state = {
      storageRoot: root,
      showCommentPopover: false,
      isLoading: false,
      showToast: false,
      likes: this.props.update.likes,
      commentThread: this.props.update.thread,
    }
  }

  /* calculate required display variables */
  async componentDidMount() {
    const likeCount = this.props.update.likes ? this.props.update.likes.length : 0;
    var userHasLiked = false;
    if (likeCount > 0 && this.props.user) {
      for (let l of this.props.update.likes) {
        if (l.likedBy && l.likedBy === this.props.user.username) {
          userHasLiked = true;
          break;
        }
      }
    }

    this.setState({
      userHasLiked: userHasLiked
    })
  }

  doLike() {
    if (this.state.userHasLiked) {
      this.setState({
        showToast: true,
        toastMsg: "Unlike coming soon, ya meanie"
      });
      return;
    }

    this.setState({isLoading: true});

    const path = '/puzzles/updates/like/' + this.props.update.updateId;
    const newLike = {
      likedBy: this.props.user.username,
      likedByDisplay: this.props.user.attributes['custom:displayName'],
      date: Date.now()
    }
    API.post('apic5763994', path, {
      body: newLike
    }).then(resp => {
      const newLikes = this.state.likes ?
        [...this.state.likes, newLike] : [newLike];
      this.setState({
        isLoading: false,
        likes: newLikes,
        userHasLiked: true,
        showToast: true,
        toastMsg: "Liked!"
      })

      const notif = {
        toUserId: this.props.update.userId,
        date: newLike.date,
        type: "L",
        srcUpdateId: this.props.update.updateId,
        srcUpdateStatus: this.props.update.status,
        srcUpdatePuzzleName: this.props.update.puzzleName,
        srcUserId: newLike.likedBy,
        srcUserDisplay: newLike.likedByDisplay
      }
      this.doSubmitNotification(notif);
    })
    .catch(err => {
      console.log(err);
      this.setState({
        isLoading: false,
        showToast: true,
        toastMsg: "Oops! Something went wrong."
      });
    });
  }

  doCommentClick(e) {
    this.setState({
      showCommentPopover: true,
      commentPopoverEvent: e.nativeEvent
    });
  }

  doCommentDismiss(e) {
    this.setState({
      showCommentPopover: false,
      commentPopoverEvent: null
    });
  }

  doCommentSubmit() {

    this.setState({isLoading: true});

    const path = '/puzzles/updates/comment/' + this.props.update.updateId;
    const newComment = {
      by: this.props.user.username,
      byDisplay: this.props.user.attributes['custom:displayName'],
      date: Date.now(),
      msg: this.state.commentPopoverVal
    }
    API.post('apic5763994', path, {
      body: newComment
    }).then(resp => {
      const newThread = this.state.commentThread ?
        [...this.state.commentThread, newComment] : [newComment];
      this.setState({
        isLoading: false,
        showToast: true,
        toastMsg: "Comment posted!",
        showCommentPopover: false,
        commentPopoverEvent: null,
        commentPopoverVal: "",
        commentThread: newThread,
      })

      const notif = {
        toUserId: this.props.update.userId,
        date: newComment.date,
        type: "C",
        srcUpdateId: this.props.update.updateId,
        srcUpdateStatus: this.props.update.status,
        srcUpdatePuzzleName: this.props.update.puzzleName,
        srcUserId: newComment.by,
        srcUserDisplay: newComment.byDisplay
      }
      this.doSubmitNotification(notif);
    })
    .catch(err => {
      console.log(err);
      this.setState({
        isLoading: false,
        showToast: true,
        toastMsg: "Oops! Something went wrong.",
        showCommentPopover: false,
        commentPopoverEvent: null
      });
    });
  }

  doSubmitNotification(notif) {
    if (notif.srcUserId === notif.toUserId) {
      return;
    }

    API.post('apinotifications', '/notifications', {
      body: notif
    })
    .then(resp => {})
    .catch(err => {
      console.log(err);
    });
  }

  render() {
    return (
      <IonCard>
        <IonCardHeader>
          {this.props.featured &&
            <IonCardTitle>Featured Update</IonCardTitle>
          }
          <IonCardSubtitle>
            <TimeAgo date={this.props.update.date} />
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <PhotoGrid storageRoot={this.state.storageRoot} />
          <IonRouterLink href={"/user/" + this.props.update.userId}>
            <IonChip color="primary">
              <UserAvatar mode="update-card" userLink={true} userId={this.props.update.userId} />
              <IonLabel>{this.props.update.userDisplay}</IonLabel>
            </IonChip>
          </IonRouterLink>
          {this.props.update.status === "Added" && (
            <React.Fragment>
              {" "} added <b>{this.props.update.puzzleName}</b> to the database. Thanks!
            </React.Fragment>
          )}
          {this.props.update.status === "Started" && (
            <React.Fragment>
              {" "} started building <b>{this.props.update.puzzleName}</b>!
            </React.Fragment>
          )}
          {(this.props.update.status === "In Progress" || this.props.update.status === "Complete") && (
            <>
              {this.props.update.percentComplete === 100 ? (
                <React.Fragment>
                  {" "}  finished building <b>{this.props.update.puzzleName}</b>. Cheers!
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {" "} logged <b>{this.props.update.percentComplete}%</b> progress on <b>{this.props.update.puzzleName}</b>.
                </React.Fragment>
              )}
            </>
          )}
          <br />
          {(this.props.update.comments || this.state.commentThread) && (
            <IonGrid>
              {this.props.update.comments && (
                <IonRow className="thread">
                  <IonCol size="22">
                    <div className="quote-content">{this.props.update.comments}</div>
                  </IonCol>
                </IonRow>
              )}
              {!this.props.featured && this.state.commentThread && this.state.commentThread.map((comment, index) => (
                <CommentThreadItem key={index} comment={comment} userId={this.props.update.userId} />
              ))}
            </IonGrid>
          )}

          <IonGrid>
            <IonRow>
              <LikedByChip likes={this.state.likes} />
              {this.props.user && (
                <>
                  <IonChip color="primary" onClick={this.doLike}>
                    {this.state.userHasLiked
                      ? <>
                          <IonIcon icon={chevronUpCircle} />
                          <IonLabel>Liked</IonLabel>
                        </>
                      : <>
                          <IonIcon icon={chevronUpCircleOutline} />
                          <IonLabel>Like</IonLabel>
                        </>
                    }
                  </IonChip>
                  {!this.props.featured &&
                  <>
                    <IonChip color="primary" onClick={this.doCommentClick}>
                      <IonIcon icon={chatboxOutline} />
                      <IonLabel>Comment</IonLabel>
                    </IonChip>
                    <IonPopover onDidDismiss={this.doCommentDismiss} event={this.state.commentPopoverEvent} isOpen={this.state.showCommentPopover}>
                      <IonItem>
                        <IonLabel position="stacked">Your Comment:</IonLabel>
                        <IonTextarea name="comment" maxlength={500} autocapitalize="sentences"
                          value={this.state.commentPopoverVal} onIonChange={(e: Event) => this.setState({commentPopoverVal: (e.target as HTMLInputElement).value})}></IonTextarea>
                      </IonItem>
                      <IonItem>
                        <IonButton onClick={this.doCommentSubmit}>
                          Say It
                        </IonButton>
                      </IonItem>
                    </IonPopover>
                  </>}
                </>
              )}
            </IonRow>
          </IonGrid>
        </IonCardContent>

        <IonToast
          isOpen={this.state.showToast}
          position="bottom"
          duration={3000}
          message={this.state.toastMsg}
          onDidDismiss={() => this.setState({showToast: false, toastMsg: ""})} />
      </IonCard>
    );
  }
}

export default UpdateCard;
