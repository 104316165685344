import {
  IonMenuButton, IonButtons, IonContent, IonHeader, IonPage,
  IonTitle, IonToolbar, IonItem, IonLabel, IonInput,
  IonGrid, IonRow, IonModal, IonButton,
  IonList, IonText, IonLoading } from '@ionic/react';
import React from 'react';
import { API, Auth } from 'aws-amplify';
import './Register.css';
import JitUserAvatar from '../components/JitUserAvatar';
import MenuBar from '../components/MenuBar';

type RegisterProps = {
  history
};
type RegisterState = {
  email: string,
  displayName: string,
  password1: string,
  password2: string,
  code: string,
  isLoading: boolean,
  showVerifyModal: boolean,
  showSuccessModal: boolean,
  awsUserId?: string
}

class Register extends React.Component<RegisterProps, RegisterState>{

  codeInput: any;

  constructor(props) {
    super(props);

    this.codeInput = React.createRef();
    this.handleKey = this.handleKey.bind(this);
    this.submit = this.submit.bind(this);
    this.signUp = this.signUp.bind(this);
    this.submitCode = this.submitCode.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
    this.handleError = this.handleError.bind(this);
    this.completeRegistration = this.completeRegistration.bind(this);

    this.state = {
      email: '',
      displayName: '',
      password1: '',
      password2: '',
      code: '',
      isLoading: false,
      showVerifyModal: false,
      showSuccessModal: false
    };
  }

  /* submit to AWS */
  async submit(event: any) {
    event.preventDefault();
    this.setState({isLoading: true});

    if (!(this.state.email && this.state.displayName && this.state.password1 && this.state.password2)) {
      alert('All fields are required; try again.');
      this.setState({isLoading: false});
      return;
    } else if (this.state.password1 !== this.state.password2) {
      alert('Passwords do not match; try again.');
      this.setState({isLoading: false});
      return;
    }

    API.get('api2d19afaf', '/users?displayName=' + this.state.displayName, {})
      .then(resp => {
        if (resp && resp.length > 0) {
          alert('That user display name is taken; try again.');
          this.setState({isLoading: false});
          return;
        } else
          this.signUp();
      })
      .catch(err => this.handleError("Error validating user", err));
  }

  async signUp() {
    Auth.signUp({
      username: this.state.email,
      password: this.state.password1,
      attributes: {
        email: this.state.email,
        "custom:displayName": this.state.displayName
      },
      validationData: []})
      .then(data => this.handleSignUp(data))
      .catch(err => this.handleError("Signup error", err));
  }

  /* send verification code to Cognito */
  async submitCode(event: any) {
    event.preventDefault();

    if (!this.state.code) {
      alert('Verification code is blank; try again.');
      return;
    }

    this.setState({isLoading: true});
    Auth.confirmSignUp(this.state.email, this.state.code)
      .then(data => this.completeRegistration(data))
      .catch(err => this.handleError("Verification error", err));
  }

  handleSignUp(data) {
    this.setState({isLoading: false, showVerifyModal: true});
  }

  handleError(msg, err) {
    this.setState({isLoading: false});
    alert(msg + (err.message ? ": " + err.message : ""));
    console.log(err);
  }

  completeRegistration(data) {
    console.log(data);
    this.setState({showVerifyModal: false});
    Auth.signIn(this.state.email, this.state.password1)
      .then(user => {
        API.post('api2d19afaf', '/users', {
          body: {
            userId: user.username,
            displayName: user.attributes['custom:displayName'],
            lowerDisplayName: user.attributes['custom:displayName'].toLowerCase().trim(),
            joinDate: Date.now()
          }
        }).then(resp => {
          this.setState({
            awsUserId: user.username,
            showSuccessModal: true,
            isLoading: false
          });
          setTimeout(() => {
            this.props.history.push('/');
            this.setState({showSuccessModal: false})
          }, 3000);
        })
        .catch(err => this.handleError("Error registering user", err));
      })
      .catch(err => this.handleError("Error registering user", err));
  }

  handleKey(e) {
    if (e.key === "Enter") {
      this.submit(e);
    }
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <MenuBar />
        </IonHeader>
        <IonContent>
          <IonItem>
            <IonLabel position="stacked">Email Address</IonLabel>
            <IonInput type="email" name="email" value={this.state.email} onIonChange={(e: Event) => this.setState({email: (e.target as HTMLInputElement).value})} ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Display Name</IonLabel>
            <IonInput name="displayName" value={this.state.displayName} onIonChange={(e: Event) => this.setState({displayName: (e.target as HTMLInputElement).value})} ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Password</IonLabel>
            <IonInput type="password" name="password1" value={this.state.password1} onIonChange={(e: Event) => this.setState({password1: (e.target as HTMLInputElement).value})} ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Verify Password</IonLabel>
            <IonInput type="password" name="password2" value={this.state.password2} onKeyUp={this.handleKey} onIonChange={(e: Event) => this.setState({password2: (e.target as HTMLInputElement).value})} ></IonInput>
          </IonItem>

          <IonGrid><IonRow>
            <IonButton onClick={this.submit}>
              Register
            </IonButton>
          </IonRow></IonGrid>

          <IonModal cssClass="auto-height"
            isOpen={this.state.showVerifyModal}
            backdropDismiss={false}
            onDidPresent={() => this.codeInput.current.setFocus()} >
            <IonHeader><IonToolbar><IonTitle>Verify Email</IonTitle></IonToolbar></IonHeader>
            <div className="modal-content">
              <IonList>
                <IonItem>
                  <IonLabel position="stacked">We sent you an email; enter the verification code from your inbox:</IonLabel>
                  <IonInput placeholder="Enter code here" ref={this.codeInput} name="code" value={this.state.code} onIonChange={(e: Event) => this.setState({code: (e.target as HTMLInputElement).value})} ></IonInput>
                </IonItem>
              </IonList>
              <IonGrid><IonRow class="ion-justify-content-center">
                <IonButton onClick={this.submitCode}>Verify</IonButton>
              </IonRow></IonGrid>
            </div>
          </IonModal>

          <IonModal cssClass="auto-height" isOpen={this.state.showSuccessModal}>
            <IonHeader><IonToolbar><IonTitle>Success!</IonTitle></IonToolbar></IonHeader>
            <div className="modal-content">
              <IonGrid>
                <IonRow class="ion-justify-content-center ion-align-items-center">
                  {this.state.awsUserId && (
                    <React.Fragment>
                      <JitUserAvatar userId={this.state.awsUserId} />
                      <IonText>All signed up! Sending you back home... </IonText>
                    </React.Fragment>
                  )}
                </IonRow>
              </IonGrid>
            </div>
          </IonModal>

          <IonLoading isOpen={this.state.isLoading} />
        </IonContent>
      </IonPage>
    );
  }
}

export default Register;
