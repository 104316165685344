import {
  IonContent, IonHeader, IonPage,
  IonItem, IonLabel, IonToast, IonSegment, IonSegmentButton,
  IonList, IonSearchbar, IonNote,
  withIonLifeCycle} from '@ionic/react';
import React from 'react';
import { API, Auth} from 'aws-amplify';
import PhotoSeriesPreview from '../components/PhotoSeriesPreview';
import UserAvatar from '../components/UserAvatar';
import BuildStartedModal from '../components/BuildStartedModal';
import MenuBar from '../components/MenuBar';
import Puzzle from '../types/Puzzle';
import TimeAgo from 'react-timeago';
import './PuzzleList.css';

type NotificationsProps = {
}
type NotificationsState = {
  user?: any,
  notifs?: any,
  resultsReady: boolean
}

class Notifications extends React.Component<NotificationsProps, NotificationsState>{

  constructor(props) {
    super(props);

    this.getNotifications = this.getNotifications.bind(this);

    this.state = {
      resultsReady: false
    };
  }

  async ionViewWillEnter() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({user: user});
        this.getNotifications();
      })
      .catch(err => {
      });
  }

  async getNotifications() {
    if (!this.state.user) {
      return;
    }
    API.get('apinotifications', '/notifications/' + this.state.user.username, {})
    .then(response => {
      this.setState({notifs : response, resultsReady: true});

      setTimeout(() => {
        for(let n of this.state.notifs) {
          API.del('apinotifications', '/notifications/object/' + this.state.user.username + '/' + n.date, {})
          .then(response => {})
          .catch(err => console.log(err))
        }
      }, 2000);
    })
    .catch(err => {
      console.log(err);
      this.setState({notifs: [], resultsReady: true});
    })
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <MenuBar active="notifications"/>
        </IonHeader>
        <IonContent>
          <IonList className="ion-align-items-center">
            {this.state.resultsReady && this.state.notifs && this.state.notifs.length === 0 && (
              <IonItem>Nothing to display.</IonItem>
            )}
            {this.state.notifs && this.state.notifs.map((notif, index) => (
              <IonItem key={index} button detail>
                <UserAvatar userId={notif.srcUserId} mode="menu" />
                <IonLabel>
                  {' ' + notif.srcUserDisplay + ' '}
                  {notif.type === "L" &&
                    <>
                      liked your <b>{notif.srcUpdatePuzzleName}</b> update.
                    </>
                  }
                  {notif.type === "C" &&
                    <>
                      commented on your <b>{notif.srcUpdatePuzzleName}</b> update.
                    </>
                  }
                </IonLabel>
                <IonNote slot="end"><TimeAgo date={notif.date}/></IonNote>
              </IonItem>
            ))}
          </IonList>

        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(Notifications);
