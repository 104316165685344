import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
  IonList, IonItem, IonCheckbox, IonLabel, IonBadge, IonNote,
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle,
  IonCardContent, IonButtons, IonMenuButton, IonImg,
  IonButton, IonIcon,
  withIonLifeCycle } from '@ionic/react';
import React from 'react';
import { API, Auth } from 'aws-amplify';
import HomeBuildCard from '../components/HomeBuildCard';
import UpdateCard from '../components/UpdateCard';
import MenuBar from '../components/MenuBar';
import ProgressUpdate from '../types/ProgressUpdate';

type MyBuildsProps = {};
type MyBuildsState = {
  user?: any;
  updates: ProgressUpdate[];
  stats?: any;
  featuredUpdate?: ProgressUpdate;
};

class MyBuilds extends React.Component<MyBuildsProps, MyBuildsState>{

  constructor(props) {
    super(props);

    this.loadHomeCards = this.loadHomeCards.bind(this);

    this.state = {
      updates: []
    };
  }

  async ionViewWillEnter() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({user: user});
        this.loadHomeCards();
      })
      .catch(err => {
        this.loadHomeCards();
      });
  }

  async loadHomeCards() {

    if (this.state.user) {
      let response = await API.get('apic5763994', '/puzzles/updates?userId=' + this.state.user.username, {});
      var latestUpdates = new Map();
      var doneBuilds = [];
      for (let u of response) {
        const update = {
          userId: u.userId,
          userDisplay: u.userDisplay,
          buildId: u.buildId,
          updateId: u.updateId,
          date: u.date,
          puzzleId: u.puzzleId,
          puzzleName: u.puzzleName,
          percentComplete: u.percentComplete,
          comments: u.comments,
          status: u.status
        };

        if (update.status === "Added") {
          continue;
        }
        if (update.status === "Complete") {
          doneBuilds.push(update.buildId);
        }
        if (doneBuilds.includes(update.buildId)) {
          latestUpdates.delete(update.buildId);
          continue;
        }

        if(!latestUpdates.has(update.buildId)) {
          latestUpdates.set(update.buildId, update);
        } else if (update.date > latestUpdates.get(update.buildId).date) {
          latestUpdates.set(update.buildId, update);
        }
      }
      let updates = Array.from(latestUpdates.values());
      updates.sort((a, b) => (a.date < b.date) ? 1 : -1)
      this.setState({updates : updates});
    }
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <MenuBar active="my-builds" />
        </IonHeader>
        <IonContent>
          {!this.state.user && (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Log Your Progress</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                Puzzfeed lets you track your current puzzle progress and puzzling history over time.<br />
                <a href="/register">Register</a> or <a href="/sign-in">sign in</a> to start tracking your builds.<br /><br />
                Or, browse our existing <a href="/puzzle-list">catalog</a> of puzzles.
              </IonCardContent>
            </IonCard>
          )}
          {this.state.user && (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Hi {this.state.user.attributes['custom:displayName']}!</IonCardTitle>
              </IonCardHeader>
              {this.state.updates && this.state.updates.length > 0
              ?
                <IonCardContent>
                  Your active builds are listed below. <br />
                  Access the <a href="/puzzle-list">catalog</a> to browse or start a new puzzle!
                </IonCardContent>
              :
                <IonCardContent>
                  You have no active puzzles at the moment. <br />
                  Access the <a href="/puzzle-list">catalog</a> to browse or start a new puzzle!
                </IonCardContent>
              }
            </IonCard>
          )}

          {this.state.updates.map((update, index) => (
            <HomeBuildCard key={update.updateId} update={update} />
          ))}

        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(MyBuilds);
