import {
  IonApp, IonRouterOutlet, IonMenu, IonHeader, IonContent,
  IonToolbar, IonList, IonItem, IonTitle, IonMenuToggle
 } from '@ionic/react';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import Amplify, {Analytics} from 'aws-amplify';
import awsconfig from './aws-exports';

import UserMenu from './components/UserMenu';
import Home from './pages/Home';
import NewPuzzle from './pages/NewPuzzle';
import PuzzleList from './pages/PuzzleList';
import MyBuilds from './pages/MyBuilds';
import Notifications from './pages/Notifications';
import NewStatusUpdate from './pages/NewStatusUpdate';
import ProgressFeed from './pages/ProgressFeed';
import Register from './pages/Register';
import SignIn from './pages/SignIn';
import Forgot from './pages/Forgot';
import UserProfile from './pages/UserProfile';
import Download from './pages/Download';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global.scss';

/* Google Analytics */
import ReactGA from 'react-ga';

ReactGA.initialize('UA-163424877-1');
ReactGA.pageview(window.location.pathname + window.location.search);

Amplify.configure(awsconfig);

const App: React.FC = () => (

  <IonApp>
    <div className="App">
      <UserMenu />
      <IonReactRouter>
        <IonRouterOutlet id="main">
          <Redirect exact path="/" to="/home" />
          <Route path="/home" component={ProgressFeed} exact={true} />
          <Route path="/my-builds" component={MyBuilds} exact={true} />
          <Route path="/notifications" component={Notifications} exact={true} />
          <Route path="/register" component={Register} exact={true} />
          <Route path="/sign-in" component={SignIn} exact={true} />
          <Route path="/forgot" component={Forgot} exact={true} />
          <Route path="/puzzle-list" component={PuzzleList} exact={true} />
          <Route path="/new-puzzle" component={NewPuzzle} exact={true} />
          <Route path="/add-status/:id" component={NewStatusUpdate} />
          <Route path="/feed" component={ProgressFeed} />
          <Route path="/user/:id" component={UserProfile} />
        </IonRouterOutlet>
      </IonReactRouter>
    </div>
  </IonApp>
);

export default App;
